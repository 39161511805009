import { Dialog } from "@mui/material";

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  onProceed: () => void;
  successBtnClass?: string;
  successBtnLabel?: string;
  failureBtnLabel?: string;
  hideWarnIcon?: boolean;
}

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  subtitle,
  onProceed,
  successBtnClass,
  successBtnLabel,
  hideWarnIcon,
  failureBtnLabel,
}: IProps) {
  return (
    <Dialog open={open} onClose={onClose} id="confirm-dialog">
      <div className="p-40">
        <h2 className="divCenterAlign">{title} </h2>
        <div className="divCenterAlign">
          {!hideWarnIcon && (
            <img
              src={"/assets/icons/warning.png"}
              className="iconSizeLarge"
              alt="warning"
            />
          )}

          <p style={{ margin: "0px 20px" }}>{subtitle}</p>
        </div>

        <div className="divSpread" style={{ marginTop: 40 }}>
          <button className="bigButtonGrey" onClick={onClose}>
            {failureBtnLabel || "No"}
          </button>
          <button
            className={successBtnClass || "bigButtonRed"}
            onClick={onProceed}
          >
            {successBtnLabel || "Yes"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
