import { countries } from "../../utils/constants";

export interface ICountryDropdownComponentProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
  width?: number;
  value: string;
}

export default function CountryDropdown({
  onChange,
  defaultValue,
  value,
  width,
}: ICountryDropdownComponentProps) {
  return (
    <select
      id="country-select"
      value={value}
      onChange={onChange}
      style={{ width: width }}
    >
      {countries.map((country: string, index: number) => {
        return (
          <option key={index} value={country}>
            {country}
          </option>
        );
      })}
    </select>
  );
}
