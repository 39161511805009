import {
  Button,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";

import moment from "moment-timezone";
import { useCallback, useEffect, useRef, useState } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { Link, useParams } from "react-router-dom";
import {
  useAddCustomStatusMoneyTransfer,
  useRefundOrderMutation,
} from "../../api/api";
import { useRemoveCustomStatusMoneyTransfer } from "../../api/api.delete";
import {
  useGetMoneyTransferBatchPayoutDetailsForAdmin,
  useGetMoneyTransferByIdForAdmin,
  useGetMoneyTransferFeesForAdmin,
  useGetMoneyTransferOrderCaptureDetailsForAdmin,
  useGetMoneyTransferPayoutsForAdmin,
  useGetMoneyTransferPayoutsItemDetailsForAdmin,
  useGetMoneyTransferStatusesByIdForAdmin,
  useGetMoneyTransferWebHooks,
} from "../../api/api.get";
import { useUpdateTransferEnabled } from "../../api/api.update";
import FormRow from "../../components/forms/form.row.component";
import SuccessPill from "../../components/success-pill";
import { IMoneyTransferWebHook } from "../../types/types";
import { isMoneyTransferComplete } from "../../utils/functions";
import ConfirmationDialog2 from "../../components/dialog/confirmation.dialog2.component";
export default function AdminMoneyTransfersViewUserPage() {
  const { id } = useParams();
  const { data: transfer, refetch: refetchTransfer } =
    useGetMoneyTransferByIdForAdmin(id);
  const { data: payout } = useGetMoneyTransferPayoutsForAdmin(id);
  const { data: payoutItemStatus } =
    useGetMoneyTransferPayoutsItemDetailsForAdmin(id);

  const { data: batchPayoutStatus } =
    useGetMoneyTransferBatchPayoutDetailsForAdmin(id);

  const { data: fees } = useGetMoneyTransferFeesForAdmin(id);

  const { data: orderCaptureDetails } =
    useGetMoneyTransferOrderCaptureDetailsForAdmin(id);
  const { data: statuses, refetch: refetchStatuses } =
    useGetMoneyTransferStatusesByIdForAdmin(id);
  const { data: webhooks } = useGetMoneyTransferWebHooks(transfer?.external_id);
  const [statusesRowData, setStatusesRowData] = useState<any[]>([]);
  const [feesRowData, setFeesRowData] = useState<any[]>([]);
  const [webhookView, setWebhookView] = useState<IMoneyTransferWebHook>();
  const { mutateAsync: enableTransferAsync } = useUpdateTransferEnabled(
    transfer?.id
  );

  const { mutateAsync: refundOrderAsync } = useRefundOrderMutation(
    transfer?.id
  );

  const [statusOverride, setStatusOverride] = useState<string>();
  const [deleteStatusObj, setDeleteStatusObj] = useState<any>();

  const { mutateAsync: addCustomStatusAsync } =
    useAddCustomStatusMoneyTransfer(id);
  const { mutateAsync: removeCustomStatusAsync } =
    useRemoveCustomStatusMoneyTransfer(id!!, deleteStatusObj?.status);

  const activateOrDeactivateTransfer = async (value: string) => {
    try {
      enableTransferAsync({ enabled: +value === 1 });
    } catch (err) {
      console.error(err);
      alert("Error de-activating");
    }
  };

  useEffect(() => {
    if (statuses) {
      setStatusesRowData(statuses as any);
    }
  }, [statuses]);

  useEffect(() => {
    if (fees) {
      setFeesRowData(fees);
    }
  }, [fees]);

  const statusesColDefs = [
    { field: "status", width: 300 },
    {
      field: "created_date",
      width: 300,
      valueGetter: (row: any) =>
        moment
          .utc(row.data.created_date)
          .local()
          .format("DD MMM YYYY hh:mm:ss A"),
    },
  ];

  const feesColDefs = [
    { field: "status", width: 120, valueGetter: (row: any) => row.data.amount },
    {
      field: "Charged To",
      width: 200,
      valueGetter: (row: any) => row.data.charged_to,
    },
    { field: "Where?", width: 1200, valueGetter: (row: any) => row.data.type },
  ];

  const gridRef = useRef<AgGridReact>(null);

  const addCustomStatusHandler = () => {
    addCustomStatusAsync({ status: statusOverride })
      .then(() => {
        setStatusOverride("");
        refetchStatuses();
        refetchTransfer();
      })
      .catch((err) => {
        alert("Error!");
        console.error(err);
      });
  };

  const removeForceCompleteHandler = () => {
    removeCustomStatusAsync({})
      .then(() => {
        setDeleteStatusObj(null);
        refetchStatuses();
        refetchTransfer();
      })
      .catch((err: any) => {
        alert("Error!");
        console.error(err);
      });
  };

  const refundOrderHandler = (action: "CANCEL_PAYOUT" | "REFUND_ORDER") => {
    refundOrderAsync({ action, description: undefined })
      .then(() => {})
      .catch((err) => {
        alert(JSON.stringify(err.response.data?.message));
        console.error(err);
      });
  };

  return (
    <div className="body">
      <ConfirmationDialog2
        open={!!deleteStatusObj}
        onClose={() => setDeleteStatusObj(undefined)}
        title={"Delete status - " + deleteStatusObj?.status}
        subtitle="Are you sure you want to delete this status?"
        onProceed={() => removeForceCompleteHandler()}
      />
      <Dialog
        open={!!webhookView}
        title={""}
        onClose={() => setWebhookView(undefined)}
      >
        <JsonView
          data={webhookView?.data_json}
          shouldExpandNode={allExpanded}
          style={defaultStyles}
        />
      </Dialog>

      <div style={{ margin: "0% 20% 10% 20%" }}>
        <div className="my-20">
          <Link to="/admin/money-transfers">Back</Link>
        </div>

        <div className="divSpread">
          <h1>{transfer?.type}</h1>
        </div>

        <div className="my-20">
          <SuccessPill
            success={isMoneyTransferComplete(transfer?.status)}
            title={transfer?.status}
            errorColor="var(--exhut-red)"
          />
        </div>

        <div className="my-10">
          {payoutItemStatus?.transaction_status !== "SUCCESS" && (
            <span className="errorMsg">
              This transfer has <strong>unsuccessful</strong> payout. This is
              eligible for refund to sender.
            </span>
          )}
        </div>

        <div className="divColumn">
          <FormRow label="PayPal Order Id" textSize="x-large">
            {transfer?.external_id ?? "--"}

            <div className="my-10" style={{ fontSize: 20 }}>
              Order Capture Status :{" "}
              <strong>{orderCaptureDetails?.status}</strong>
              <div className="my-10">
                <div className="my-10">
                  {orderCaptureDetails?.purchase_units?.map(
                    (purchaseUnit, i) => {
                      return (
                        <div className="divColumn">
                          <div className="section">
                            <strong>purchase_units.payments.captures </strong>

                            <ol>
                              {purchaseUnit.payments?.captures.map(
                                (capture) => {
                                  return (
                                    <li>
                                      <div className="divColumn">
                                        <strong>ID: {capture.id}</strong>
                                        <span>
                                          Amount: {capture.amount.value}{" "}
                                        </span>
                                        <span
                                          className={
                                            capture.status !== "COMPLETED"
                                              ? "errorMsg"
                                              : "successMsg"
                                          }
                                        >
                                          Status: {capture.status}{" "}
                                        </span>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                          </div>

                          <div className="section my-10">
                            <strong>purchase_units.payments.refunds </strong>

                            <ol>
                              {purchaseUnit.payments?.refunds?.map((refund) => {
                                return (
                                  <li>
                                    <div className="divColumn">
                                      <strong>ID: {refund.id}</strong>
                                      <span>
                                        Amount: {refund.amount.value}{" "}
                                      </span>
                                      <span
                                        className={
                                          refund.status !== "COMPLETED"
                                            ? "errorMsg"
                                            : "successMsg"
                                        }
                                      >
                                        Status: {refund.status}{" "}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            </ol>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <strong>Refunds</strong>
                <div className="my-10">
                  <ul>
                    {orderCaptureDetails?.purchase_units?.map(
                      (purchaseUnit) => {
                        return (
                          <li>{purchaseUnit.payments?.refunds?.[0].status}</li>
                        );
                      }
                    )}
                  </ul>
                </div>

                {payoutItemStatus?.transaction_status !== "SUCCESS" && (
                  <Button
                    onClick={() => refundOrderHandler("REFUND_ORDER")}
                    variant="contained"
                    color="error"
                  >
                    Refund Order
                  </Button>
                )}
              </div>
            </div>
          </FormRow>

          <hr />
          <FormRow label="Batch Payout Id (PayPal)" textSize="x-large">
            {payout?.payout_batch_id ?? "--"}

            <SuccessPill
              success={
                batchPayoutStatus?.batch_header.batch_status === "SUCCESS"
              }
              title={payoutItemStatus?.transaction_status}
              errorColor="var(--exhut-red)"
            />
          </FormRow>

          <hr />
          <FormRow label="Payout Item Id" textSize="x-large">
            {payout?.payout_item_id ?? "--"}

            <SuccessPill
              success={payoutItemStatus?.transaction_status === "SUCCESS"}
              title={payoutItemStatus?.transaction_status}
              errorColor="var(--exhut-red)"
            />
            {payoutItemStatus?.transaction_status !== "SUCCESS" && (
              <div className="divColumn">
                Error Name:{" "}
                <span className="errorMsg">
                  {payoutItemStatus?.errors?.name}
                </span>
                Error Message:
                <span className="errorMsg">
                  {payoutItemStatus?.errors?.message}
                </span>
              </div>
            )}

            <div>
              <Button
                variant="contained"
                color={"error"}
                onClick={() => refundOrderHandler("CANCEL_PAYOUT")}
              >
                Cancel Payout
              </Button>
            </div>
          </FormRow>

          <hr />
          <FormRow label="Sender Email" textSize="x-large">
            {transfer?.sender_email ?? "--"}
          </FormRow>
          <hr />

          <FormRow label="Recipient Email" textSize="x-large">
            {transfer?.recipient_email ?? "--"}
          </FormRow>

          <hr />
          <FormRow label="Recipient Username" textSize="x-large">
            {transfer?.recipient ?? "--"}
          </FormRow>
          <hr />

          <FormRow label="Amount" textSize="x-large">
            ${transfer?.amount ?? "--"}
          </FormRow>
          <hr />
          <h3>Fees (Loss to ExpenseHut)</h3>
          <h3>
            $
            {(fees?.map((f) => f.amount) ?? []).reduce(
              (acc, currentValue) => acc + currentValue,
              0
            )}
          </h3>
          <div
            className="ag-theme-quartz" // applying the grid theme
            style={{ height: 200, width: 500 }} // the grid will fill the size of the parent container
          >
            <AgGridReact
              rowData={feesRowData}
              columnDefs={feesColDefs as any}
            />
          </div>

          <hr />

          <h3>Statuses</h3>

          <div
            className="ag-theme-quartz" // applying the grid theme
            style={{ height: 700, width: 600 }} // the grid will fill the size of the parent container
          >
            <AgGridReact
              ref={gridRef}
              rowData={statusesRowData}
              columnDefs={statusesColDefs as any}
              onRowClicked={(params) => {
                [
                  ExHutCustomTransferStatus.CANCELLED,
                  ExHutCustomTransferStatus.FORCE_COMPLETE,
                  ExHutCustomTransferStatus.REFUNDED,
                ].includes(params.data.status) &&
                  setDeleteStatusObj(params.data);
              }}
            />
          </div>
          <div className="my-20 divSpread" style={{ width: 300 }}>
            <select onChange={(e) => setStatusOverride(e.target.value)}>
              <option value="">--</option>
              <option value={"EXHUT_FORCE_COMPLETE"}>COMPLETE</option>
              <option value={"EXHUT_REFUNDED"}>REFUNDED</option>
              <option value={"EXHUT_CANCELLED"}>CANCELLED</option>
            </select>

            <Button
              variant="contained"
              disabled={!statusOverride}
              onClick={() => addCustomStatusHandler()}
            >
              Add Status
            </Button>
          </div>

          <div className="my-40">
            <h3>WebHooks</h3>

            {webhooks &&
              webhooks.map((hook: IMoneyTransferWebHook, index: number) => {
                return (
                  <div className="divColumn my-20" key={index}>
                    <input
                      className="buttonAsLink"
                      onClick={() => setWebhookView(hook)}
                      value={hook.id}
                    />
                    <div className="my-10">
                      <strong>{hook.data_json.event_type}</strong>
                    </div>

                    <span
                      className={hook.acknowledged ? "successMsg" : "errorMsg"}
                    >
                      {hook.acknowledged ? "Acknowledged" : "NOT Acknowledged"}
                    </span>

                    <div className="my-10">
                      <strong>
                        {moment
                          .utc(hook.created_date)
                          .local()
                          .format("DD MMM YYYY hh:mm:ss A")}
                      </strong>
                    </div>

                    <hr />
                  </div>
                );
              })}
          </div>

          <div>
            <FormRow label="Entitlement">
              <RadioGroup
                name="radio-buttons-group"
                value={transfer?.disabled}
                onChange={(e) => activateOrDeactivateTransfer(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio checked={!transfer?.disabled} />}
                  label="Enabled"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio checked={transfer?.disabled} />}
                  label="Disabled"
                />
              </RadioGroup>
            </FormRow>
          </div>
        </div>
      </div>
    </div>
  );
}

export enum ExHutCustomTransferStatus {
  FORCE_COMPLETE = "EXHUT_FORCE_COMPLETE",
  CANCELLED = "EXHUT_CANCELLED",
  REFUNDED = "EXHUT_REFUNDED",
}
