import { EXHUT_REDIRECT } from "../utils/constants";

export default function SuccessBanner({ msg }: { msg: string }) {
  const size = 40;
  const redirect = localStorage.getItem(EXHUT_REDIRECT);

  return (
    <div className="p-16">
      <div className="divCenterAlign divColumn">
        <img
          src="/assets/checked.png"
          alt="success"
          height={size}
          width={size}
        />

        <div className="my-20">
          <strong>{msg}</strong>
        </div>

        {redirect && (
          <div className="my-40">
            <a href={redirect} rel="noreferrer" className="linkAsGreenButton">
              Proceed
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
