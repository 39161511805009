import { PropsWithChildren } from "react";
import { useAuthContext } from "../providers/auth.provider";
import NoAccessPage from "../pages/no-access.page";

export default function RequireAdminAccess(props: PropsWithChildren) {
  const { user } = useAuthContext();

  if (user?.admin === true) {
    return <> {props.children} </>;
  }

  return <NoAccessPage />;
}
