import { PropsWithChildren, createContext, useContext } from "react";

interface ILayoutContext {
  isMobile?: boolean;
}

export const LayoutContext = createContext<ILayoutContext>({});

export const useLayoutContext = () => useContext(LayoutContext);

export default function LayoutProvider({ children }: PropsWithChildren<{}>) {
  const userAgent = window.navigator.userAgent;
  const isMobile =
    userAgent !== undefined &&
    (userAgent.includes("Android") || userAgent.includes("iPhone"));

  return (
    <LayoutContext.Provider value={{ isMobile }}>
      {children}
    </LayoutContext.Provider>
  );
}
