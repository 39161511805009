import { Link, useNavigate } from "react-router-dom";
import LogoComponent from "../components/leftpane/items/logo.component";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import LeftPaneItems from "./leftpane/items/leftpane.items";
import { useLayoutContext } from "../providers/layout.provider";
import { useAuthContext } from "../providers/auth.provider";

export default function MobileHeader() {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const navigate = useNavigate();
  const { isMobile } = useLayoutContext();
  const { user, signout } = useAuthContext();

  const handleClick = (index: number) => {
    setAnchorEl(null);
    if (index === 0) {
      window.location.href = "https://www.expensehut.com";
    } else if (index === 1) {
      navigate("/settings/profile");
    } else if (index === 2) {
      navigate("/settings/change-password");
    } else if (index === 3) {
      signout();
    }
  };

  return (
    <div className="bg-purple">
      <div className="divSpread divAlignItemsOnly">
        <LogoComponent user={user} />

        {isMobile && (
          <>
            {" "}
            <MobileOptionsMenu
              id="toggle-options"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <LeftPaneItems
              anchorEl={anchorEl}
              open={!!anchorEl}
              onPostClick={handleClick}
            />
          </>
        )}

        {!isMobile && (
          <div className="divAlignItemsOnly">
            <Link
              className="mx-20"
              to={user ? "/home" : "/"}
              style={{ color: "white", textDecoration: "none" }}
            >
              Home
            </Link>
            <Link
              className="mx-20"
              to="/about"
              style={{ color: "white", textDecoration: "none" }}
            >
              About Us
            </Link>

            <Link
              className="mx-20"
              to="/products"
              style={{ color: "white", textDecoration: "none" }}
            >
              Products
            </Link>

            {!user && (
              <Link
                to="/register"
                className="mx-20"
                style={{ color: "white", textDecoration: "none" }}
              >
                Sign up{" "}
              </Link>
            )}

            {user && (
              <Link
                to="/settings"
                className="mx-20"
                style={{ color: "white", textDecoration: "none" }}
              >
                Settings
              </Link>
            )}

            <a
              className="mx-20"
              target="_blank"
              rel="noreferrer"
              href="https://help.expensehut.com"
              style={{ color: "white", textDecoration: "none" }}
            >
              Help
            </a>

            {user && (
              <Link
                to="/login?logout=1"
                className="mx-20"
                style={{ color: "white", textDecoration: "none" }}
                onClick={() => signout()}
              >
                Logout
              </Link>
            )}

            <div className="mx-10">
              {" "}
              <OptionsMenu
                id="desktop-toggle-options"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              />
              <LeftPaneItems
                anchorEl={anchorEl}
                open={!!anchorEl}
                onPostClick={handleClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function MobileOptionsMenu(props: React.HTMLProps<{}>) {
  return (
    <div style={{ height: 40, marginBottom: 40 }}>
      <IconButton
        color="default"
        aria-label="open drawer"
        onClick={props.onClick}
        edge="start"
      >
        <MenuIcon htmlColor="white" />
      </IconButton>
    </div>
  );
}

export function OptionsMenu(props: React.HTMLProps<{}>) {
  return (
    <div className="divCenterAlign">
      <IconButton
        color="default"
        aria-label="open drawer"
        onClick={props.onClick}
        edge="start"
      >
        <MenuIcon htmlColor="white" />
      </IconButton>
    </div>
  );
}
