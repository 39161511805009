export function getAppURL() {
  return process.env.REACT_APP_URL ?? "https://hub.expensehut.com";
}

export function getRecaptchaKey() {
  return process.env.REACT_APP_RECAPTCHA_KEY ?? "";
}

export const isRecaptchaEnabled =
  process.env.REACT_APP_RECAPTCHA_ENABLED === "true";
