import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LanguageIcon from "@mui/icons-material/Language";
import PaidIcon from "@mui/icons-material/Paid";
import WorkIcon from "@mui/icons-material/Work";
import FeedbackComponent from "../components/other/feedback.component";
import RewardsComponent from "../components/rewards.component";
import Tile from "../components/tile.component";
import { useLayoutContext } from "../providers/layout.provider";
import React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Box, IconButton, SwipeableDrawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useGetProfile } from "../api/api.get";
import capitalize from "capitalize";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
type Anchor = "top" | "left" | "bottom" | "right";

export default function HomePage() {
  const { isMobile } = useLayoutContext();
  const { data: profile } = useGetProfile();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {items.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={item.url}
                target="_blank"
                rel="noreferrer"
              >
                <ListItemText primary={item.label} />
              </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem key={0} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <Link
              to="/settings"
              style={{ color: "black", textDecoration: "none" }}
            >
              <ListItemText primary={"Settings"} />
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className={`mb-160`} id="home-body">
      <RewardsComponent />

      <div className="divSpread p-40">
        <IconButton
          color="default"
          onClick={toggleDrawer("left", true)}
          edge="start"
        >
          <MenuIcon htmlColor="purple" />
        </IconButton>
        <React.Fragment key={"left"}>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
        </React.Fragment>
        Welcome {capitalize(profile?.firstName ?? "Guest")}
      </div>

      <div className={isMobile ? "divColumn divCenterAlign" : "divCenterAlign"}>
        <Tile
          id="home-page-account-tile"
          title={"Settings"}
          subtitle={""}
          route="/settings"
          iconComp={() => (
            <AccountCircleIcon color="secondary" fontSize="large" />
          )}
        />

        <Tile
          id="home-page-faq-tile"
          title="Support"
          subtitle="Submit your question"
          route="/support"
          iconComp={() => (
            <img
              src={"/assets/icons/question.png"}
              className="iconSizeLarge"
              alt="faq"
            />
          )}
        />
      </div>
      <FeedbackComponent />
    </div>
  );
}

const items = [
  {
    label: "ExpenseHut",
    url: "https://app.expensehut.com",
    icon: <PaidIcon fontSize="small" color="warning" />,
    external: true,
  },
  {
    label: "Recruit",
    url: "https://recruit.expensehut.com",
    icon: <WorkIcon fontSize="small" color="primary" />,
    external: true,
  },
  {
    label: "Wallet",
    url: "https://wallet.expensehut.com",
    icon: <CreditCardIcon color="success" fontSize="small" />,
    external: true,
  },
  {
    label: "Blogs",
    url: "https://blogs.expensehut.com",
    icon: <LanguageIcon color="success" fontSize="small" />,
    external: true,
  },
];
