import { useLayoutContext } from "../../providers/layout.provider";

/**
 * Renders form row component. Supports mobile views.
 *
 * @param  children : The right side component. Wrap it in a <div> Left side is usually label.
 * @param label: The left side component. Usually bolded text.
 * @returns
 */
export default function FormRow({
  children,
  label,
  mandatory,
  right,
  textSize,
  color,
}: React.PropsWithChildren<{
  label: string;
  mandatory?: boolean;
  right?: boolean;
  textSize?: string;
  color?: string;
}>) {
  return (
    <div className="divColumn line-30" style={{ marginTop: "30px" }}>
      <div className={`boldText flex-1 ${!!right ? "divRight" : ""}`}>
        {label}
        {mandatory && <sup>*</sup>}
      </div>
      <div className="flex-3 divColumn" style={{ fontSize: textSize, color }}>
        {children}
      </div>
    </div>
  );
}

/**
 * Renders form row in column only
 * @param param0
 * @returns
 */

export function FormRowCol({
  children,
  label,
  mandatory,
}: React.PropsWithChildren<{ label: string; mandatory?: boolean }>) {
  return (
    <div className="divColumn line-30" style={{ marginTop: "20px" }}>
      <div className="flex-1">
        {label}
        {mandatory && <sup>*</sup>}
      </div>
      <div className="divColumn my-10 largeText">{children}</div>
    </div>
  );
}
