import { useEffect, useState } from "react";
import "./TermsConditions.css";

export default function TermsConditionsComponent() {
  const [showDialog, setShowDialog] = useState<boolean>();
  const termsValue = localStorage.getItem("exhut-recruit-web-terms");

  useEffect(() => {
    setShowDialog(termsValue !== "done");
  }, [termsValue]);

  if (showDialog) {
    return (
      <div id="terms-container">
        <div id="terms">
          <div className="divColumn">
            <div className="divFlex">
              <div>
                This website uses cookies. For more on cookie policy, please
                visit
                <a
                  target="_blank"
                  style={{ marginLeft: "5px" }}
                  rel="noreferrer"
                  href="https://help.expensehut.com/cookies"
                >
                  cookies
                </a>
                .
              </div>
            </div>

            <div className="divFlex">
              <div>
                By visiting our website, you acknowledge that you agree to our
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ margin: "0px 5px" }}
                  href="https://help.expensehut.com/privacy"
                >
                  privacy policy
                </a>
                and
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ margin: "0px 5px" }}
                  href="https://help.expensehut.com/terms"
                >
                  terms & conditions
                </a>
                .
              </div>
            </div>

            <div className="divSpreadEven mt-20">
              <button
                onClick={() => {
                  setShowDialog(false);
                  localStorage.setItem("exhut-recruit-web-terms", "done");
                }}
              >
                AGREE
              </button>
              <button
                onClick={() => {
                  setShowDialog(false);
                  localStorage.setItem("exhut-recruit-web-terms", "done");
                }}
              >
                DISAGREE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
