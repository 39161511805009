import React from "react";
import { useLayoutContext } from "../providers/layout.provider";
import { ROUTES } from "../utils/constants";
import { Link } from "react-router-dom";

export default function ProductsPage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className="body p-40">
      <LandingPageItemLeft
        title="Small Businesses"
        imgWidth={450}
        subtitle="Track orders, sales, investments and employee management including reimbursements and payroll."
        imgName="business-2.jpg"
        even
        ternary={
          <div className={isMobile ? "divCenterAlign" : ""}>
            <a
              href="https://business.expensehut.com"
              target="_blank"
              rel="noreferrer"
              className="blue-button"
            >
              View More
            </a>
          </div>
        }
      />
      <hr className="my-20" />

      <LandingPageItemRight
        title="Personal finance dashboard"
        subtitle="Track expenses & savings. Send money to friends."
        link={{ text: "Learn More", url: "https://app.expensehut.com" }}
        imgName="bestway3.svg"
        imgWidth={300}
        even
      />
      <hr className="my-20" />

      <LandingPageItemCenter
        title="Send Money To Friends"
        subtitle="Send quick money to friends or family using our personal finance app"
        supIndex={1}
        imgName="sendmoney.svg"
        imgWidth={300}
        link={{ text: "Learn More", url: "https://app.expensehut.com" }}
      />
      <hr className="my-60" />

      <LandingPageItemRight
        title="Instant ID Verification"
        subtitle="Instantly verify your identification to get started."
        imgName="instant-id.svg"
        supIndex={2}
        link={{ text: "Learn More", url: "https://app.expensehut.com" }}
        even
      />
      <hr className="my-60" />

      <LandingPageItemLeft
        title="Find Tech Jobs"
        subtitle="Use our review-based system to filter out ghost jobs and find potential employers."
        imgName="job-reviews.png"
        imgWidth={300}
        even
        link={{ text: "Learn More", url: "https://recruit.expensehut.com" }}
      />
      <hr className="my-20" />

      <LandingPageItemCenter
        title="Split bills or receipts"
        subtitle="Use Wallet to split bills between your family or friends."
        imgName="receipt.svg"
        imgWidth={300}
        link={{ text: "Learn More", url: "https://wallet.expensehut.com" }}
      />
      <hr className="my-60" />

      <LandingPageItemRight
        title="Write Blogs"
        subtitle="Author articles and publish it for free."
        imgName="skills.png"
        supIndex={3}
        imgWidth={200}
        link={{ text: "Learn More", url: "https://blogs.expensehut.com" }}
        even
      />
      <hr className="my-60" />

      <LandingPageItemLeft
        title="Fully Secure"
        subtitle="All your data behind secured systems that follows industry standards in storage and data trasmission."
        imgName="fully-secure.svg"
        even
      />
      <hr className="my-60" />

      <div
        className="divColumn"
        style={{ color: "darkgrey", fontSize: "small", lineHeight: 1.4 }}
      >
        <span className={`body-footer-item`}>
          1. Available only to US customers.
        </span>
        <span className={`body-footer-item`}>
          2. Required only for Business or Send Money.
        </span>
        <span className={`body-footer-item`}>
          3. May limit publications based on free tier usage.
        </span>
      </div>
    </div>
  );
}

export function LandingPageItemLeft({
  title,
  subtitle,
  imgName,
  imgWidth = 300,
  even,
  ternary,
  link,
}: {
  link?: ILink;
  title: string;
  subtitle?: string;
  imgName?: string;
  imgWidth?: number;
  even?: any;
  ternary?: JSX.Element;
}) {
  const { isMobile } = useLayoutContext();

  return (
    <div
      className={
        isMobile
          ? "divColumn divCenterAlign"
          : `${even ? "divSpreadEven" : "divSpread"} my-60`
      }
    >
      <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
        <h2>{title}</h2>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>{subtitle}</p>
        </div>
        {ternary}
        {link && (
          <a href={link.url} target="_blank" rel="noreferrer">
            {link.text}
          </a>
        )}
      </div>

      {imgName && (
        <img src={`/assets/${imgName}`} width={imgWidth} alt={imgName} />
      )}
    </div>
  );
}

export function LandingPageItemRight({
  title,
  subtitle,
  imgName,
  ternary,
  imgWidth = 300,
  even,
  supIndex,
  link,
}: {
  link?: ILink;
  supIndex?: number;
  title: string;
  subtitle?: string;
  imgName?: string;
  ternary?: JSX.Element;
  imgWidth?: number;
  even?: any;
}) {
  const { isMobile } = useLayoutContext();

  if (isMobile) {
    return (
      <LandingPageItemLeft
        title={title}
        subtitle={subtitle}
        imgName={imgName}
        imgWidth={imgWidth}
      />
    );
  }

  return (
    <div className={`${even ? "divSpreadEven" : "divSpread"} my-60`}>
      {imgName && (
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <img src={`/assets/${imgName}`} width={imgWidth} alt={imgName} />
        </div>
      )}

      <div className={isMobile ? "mb-40" : "mb-20 w-40"}>
        <h2>{title}</h2>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>
            {subtitle}
            {supIndex && <sup>{supIndex}</sup>}
          </p>
        </div>
        {ternary}
        {link && (
          <a href={link.url} target="_blank" rel="noreferrer">
            {link.text}
          </a>
        )}
      </div>
    </div>
  );
}

export function LandingPageItemCenter({
  title,
  subtitle,
  imgName,
  ternary,
  imgWidth = 300,
  supIndex,
  link,
}: {
  link?: ILink;
  supIndex?: number;
  title: string;
  subtitle?: string;
  imgName?: string;
  ternary?: JSX.Element;
  imgWidth?: number;
}) {
  const { isMobile } = useLayoutContext();

  return (
    <div className={"divColumn divCenterAlign"}>
      <div className={isMobile ? "mb-40" : "mb-20 w-40"}>
        <h2>{title}</h2>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>
            {subtitle}
            {supIndex && <sup>{supIndex}</sup>}
          </p>
        </div>
        {ternary}
        {link && (
          <a href={link.url} target="_blank" rel="noreferrer">
            {link.text}
          </a>
        )}
      </div>

      {imgName && (
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <img src={`/assets/${imgName}`} width={imgWidth} alt={imgName} />
        </div>
      )}
    </div>
  );
}

interface ILink {
  url: string;
  text: string;
}
