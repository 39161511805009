export function getISOCodes(countryCode?: string | null) {
  switch (countryCode) {
    case "AUS":
      return "au";
    case "BW":
      return "in";
    case "CAN":
      return "ca";
    case "CHN":
      return "cn";
    case "IND":
      return "in";
    case "SGP":
      return "sg";
    case "UK":
      return "gb";
    default:
      return "us";
  }
}
