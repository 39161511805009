import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllMoneyTransfersForAdmin } from "../../api/api.get";
import moment from "moment";
import { isMoneyTransferComplete } from "../../utils/functions";

export default function AdminMoneyTransfersPage() {
  const { data: transfers } = useGetAllMoneyTransfersForAdmin();

  const [rowData, setRowData] = useState<any[]>([]);
  const navigate = useNavigate();

  const colDefs = [
    { field: "sender_email", filter: "agSetColumnFilter" },
    { field: "recipient_email", filter: "agSetColumnFilter" },
    {
      field: "created_date",
      valueGetter: (row: any) =>
        moment(row.data.created_date).local().format("DD MMM YYYY hh:mm:ss A"),
      filter: "agSetColumnFilter",
    },
    {
      field: "amount",
      filter: "agNumberColumnFilter",
      valueGetter: (row: any) =>
        (row.data.amount - (row.data.fee ?? 0)).toFixed(2),
    },
    {
      field: "status",
      filter: "agSetColumnFilter",
      cellStyle: (params: any) => ({
        color: isMoneyTransferComplete(params.value) ? "green" : "red",
      }),
    },
    { field: "id" },
  ];

  useEffect(() => {
    if (transfers) {
      setRowData(transfers as any);
    }
  }, [transfers]);

  return (
    <div className="body">
      <div className="my-40">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500, width: "80vw" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            onRowClicked={(e) =>
              navigate("/admin/money-transfers/" + e?.data?.id)
            }
            rowData={rowData}
            columnDefs={colDefs as any}
          />
        </div>
      </div>
    </div>
  );
}
