import { CircularProgress } from "@mui/material";

export interface LoadingButtonProps {
  loading: boolean;
  value: string;
  disabled: boolean;
}
export default function LoadingButton({
  value,
  loading,
  disabled,
}: LoadingButtonProps) {
  return (
    <div className="divAlignItemsOnly">
      {loading && (
        <CircularProgress
          size={20}
          color={"info"}
          sx={{ position: "absolute", marginLeft: "10px" }}
        />
      )}

      <button className="bigButtonGreen" disabled={disabled}>
        {value}
      </button>
    </div>
  );
}
