import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import { IStatusMsg } from "../types/types";
import React from "react";

interface IProps {
  message?: IStatusMsg;
  onClose: () => void;
}

/**
 * You must && for value.
 * @param message
 * @param onClose
 * @returns
 */
export default function StatusMessageComponent({ message, onClose }: IProps) {
  if (!message) {
    return null;
  }

  return (
    <div className="my-20" style={{ width: "auto" }}>
      <Alert
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
        severity={message.type}
      >
        {typeof message.text === "string" && message.text}
        {typeof message.text !== "string" && (message.text as any)()}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Alert>
    </div>
  );
}
