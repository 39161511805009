import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { usePostUpdateTicket } from "../../api/api";
import { useDeleteSupportTicket } from "../../api/api.delete";
import {
  useGetMySupportTickets,
  useGetMySupportTicketsById,
  useGetTicketLifecycle,
} from "../../api/api.get";
import LoadingButton from "../../components/button/loading.button";
import ConfirmationDialog from "../../components/dialog/confirmation.dialog.component";
import ErrorPanel from "../../components/error.panel.component";
import FormRow from "../../components/forms/form.row.component";
import HeaderComponent from "../../components/header.component";
import LoadingComponent from "../../components/loading.component";
import StatusMessageComponent from "../../components/status-message.component";
import { useAuthContext } from "../../providers/auth.provider";
import { useLayoutContext } from "../../providers/layout.provider";
import {
  IStatusMsg,
  ISupportTicketLifecycle,
  ISupportTicketLifecycleStatus,
  ISupportTicketLifecycleStatusNext,
} from "../../types/types";
import { capitalize } from "../../utils/functions";

export default function SupportViewPage() {
  const { user } = useAuthContext();
  const { id } = useParams();
  const { isMobile } = useLayoutContext();
  const { data: ticketData, refetch: refetchTicket } =
    useGetMySupportTicketsById(id);

  const { data: lifecycleData, refetch, isLoading } = useGetTicketLifecycle(id);
  const { mutateAsync, isLoading: isUpdateLoading } = usePostUpdateTicket(id);
  const { mutateAsync: deleteAsync } = useDeleteSupportTicket();

  const navigate = useNavigate();
  const [currentLifecycleState, setCurrentLifecycleState] =
    useState<ISupportTicketLifecycleStatus>("UNKNOWN");
  const [newLifecycleState, setNewLifecycleState] =
    useState<ISupportTicketLifecycleStatusNext>("REOPENED");
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    setNewLifecycleState(user?.admin === true ? "RESOLVED" : "REOPENED");
  }, [user]);

  useEffect(() => {
    setCurrentLifecycleState(
      lifecycleData && lifecycleData[0] ? lifecycleData[0].status : "UNKNOWN"
    );
  }, [lifecycleData]);

  const formik = useFormik({
    initialValues: {
      comments: "",
      ticketId: id || "",
    },
    validationSchema: Yup.object({
      comments: Yup.string().required(
        "Please enter the information helpful to resolve the problem."
      ),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, status: newLifecycleState })
        .then(() => {
          refetch();
          formik.resetForm();
          setStatusMsg({
            type: "success",
            text: "Ticket successfully updated.",
          });
        })
        .catch((err: any) =>
          setStatusMsg({ type: "error", text: err.response?.data.message })
        );
    },
  });

  function handleDelete() {
    deleteAsync(id!!)
      .then(() => navigate("/support"))
      .catch((err) =>
        setStatusMsg({ type: "error", text: JSON.stringify(err) })
      );
  }

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className={`splitright ${isMobile ? "mobile-splitright" : ""}`}>
      {statusMsg && (
        <StatusMessageComponent
          message={statusMsg}
          onClose={() => setStatusMsg(undefined)}
        />
      )}

      <ConfirmationDialog
        title="Delete Ticket"
        subtitle="Are you sure you would like to delete this ticket?"
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onProceed={handleDelete}
      />

      {user?.admin === true && (
        <div className="divRight mx-20">
          <button className="bigButtonRed" onClick={handleDelete}>
            Delete{" "}
          </button>
        </div>
      )}

      <div className="form-body">
        <HeaderComponent
          title={`Case Details`}
          backToPath={"/support"}
          backToLabel="All Cases"
        />

        <form onSubmit={formik.handleSubmit}>
          <FormRow label="">
            <div className="subjectCell">{capitalize(ticketData?.title)}</div>
          </FormRow>

          <FormRow label="Created On">
            {moment(lifecycleData?.[0]?.created_date).format("MMM DD YYYY")}
          </FormRow>

          <FormRow label="Status">
            <div>
              <strong
                className={`statusCell ${
                  currentLifecycleState === "RESOLVED"
                    ? `successMsg`
                    : "errorMsg"
                }`}
              >
                {currentLifecycleState}
              </strong>
            </div>
          </FormRow>

          {(lifecycleData?.[0]?.status === "RESOLVED" ||
            user?.admin === true) && (
            <>
              <FormRow label="Comments">
                <div>
                  <div>
                    <textarea
                      id="comments"
                      style={{
                        width: 400,
                        height: 200,
                        fontFamily: "sans-serif",
                        fontSize: "larger",
                      }}
                      {...formik.getFieldProps("comments")}
                    />
                  </div>

                  <ErrorPanel message={formik.errors.comments} />
                </div>
              </FormRow>
              <FormRow label="">
                <div>
                  <LoadingButton
                    value={user?.admin === true ? "Resolve" : "Re-Open"}
                    loading={isUpdateLoading}
                    disabled={
                      !formik.errors ||
                      (!user?.admin && currentLifecycleState !== "RESOLVED")
                    }
                  />
                </div>
              </FormRow>
            </>
          )}

          {currentLifecycleState === "CREATED" && (
            <FormRow label="">
              <div>
                <button
                  type="submit"
                  className="bigButtonRed"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Delete
                </button>
              </div>
            </FormRow>
          )}
        </form>
      </div>

      <hr />
      <div className="form-body">
        {lifecycleData && (
          <div className="divCenterAlign">
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar></ListItemAvatar>
                <ListItemText primary={<h3>History</h3>} />
              </ListItem>

              {lifecycleData.map(
                (lifecycle: ISupportTicketLifecycle, index: number) => (
                  <div key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>{index + 1}</ListItemAvatar>
                      <ListItemText
                        primary={lifecycle.comments}
                        secondary={
                          <div className="divSpread">
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {moment(lifecycle.last_updated)
                                .local()
                                .format("DD MMM YYYY hh:mm a")}
                            </Typography>
                            {lifecycle.status}
                          </div>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </div>
                )
              )}
            </List>
          </div>
        )}
      </div>
    </div>
  );
}
