import Fab from "@mui/material/Fab";
import FeedbackIcon from "@mui/icons-material/Feedback";

export default function FeedbackComponent() {
  return (
    <div style={{ position: "fixed", right: 40, bottom: 120 }}>
      <a
        href="https://sabarishnarain.typeform.com/to/MKv1e46T"
        target="blank"
        rel="noreferrer"
        style={{}}
      >
        <Fab color="primary" aria-label="add">
          <FeedbackIcon style={{ color: "white" }} />
        </Fab>
      </a>
    </div>
  );
}
