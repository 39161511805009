import { Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { usePostCreateSupportTicket } from "../../api/api";
import { useGetMySupportTickets } from "../../api/api.get";
import ErrorPanel from "../../components/error.panel.component";
import FormRow from "../../components/forms/form.row.component";
import HeaderComponent from "../../components/header.component";
import LoadingComponent from "../../components/loading.component";
import StatusMessageComponent from "../../components/status-message.component";
import { useAuthContext } from "../../providers/auth.provider";
import { useLayoutContext } from "../../providers/layout.provider";
import { IStatusMsg, ISupportTicket } from "../../types/types";
import { capitalize } from "../../utils/functions";
const FORMIK_LOCALE_MORE_WORDS =
  "Please enter few more words to describe the problem";

export default function SupportPage() {
  const { user } = useAuthContext();

  const { mutateAsync, isLoading } = usePostCreateSupportTicket();
  const { data, refetch, isLoading: isFetchLoading } = useGetMySupportTickets();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const { isMobile } = useLayoutContext();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title."),
      description: Yup.string().min(5, FORMIK_LOCALE_MORE_WORDS).required(),
    }),

    onSubmit: async (values) => {
      // alert(JSON.stringify(values))
      mutateAsync(values)
        .then(() => {
          formik.resetForm();
          refetch();
          setStatusMsg({
            type: "success",
            text: "Ticket successfully created. Please allow 2-4 days for us to response.",
          });
        })
        .catch((err) =>
          setStatusMsg({
            type: "error",
            text: "Error updating ticket. Please try again.",
          })
        );
    },
  });

  if (isFetchLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className={`splitright ${isMobile ? "mobile-splitright" : ""}`}>
      {statusMsg && (
        <StatusMessageComponent
          message={statusMsg}
          onClose={() => setStatusMsg(undefined)}
        />
      )}

      <div className={`form-body ${isMobile ? "w-100" : "w-60"}`}>
        {!user?.admin && (
          <div className="flex-1">
            <div className="p-20">
              <HeaderComponent
                title={user?.admin === true ? "All Tickets" : "Support Ticket"}
              />

              <form onSubmit={formik.handleSubmit}>
                <FormRow label="Subject">
                  <div>
                    <div>
                      <input
                        type="text"
                        id="title"
                        className="bordered"
                        maxLength={60}
                        {...formik.getFieldProps("title")}
                      />
                    </div>

                    <ErrorPanel message={formik.errors.title} />
                  </div>
                </FormRow>

                <FormRow label="Description">
                  <div>
                    <div>
                      <textarea
                        style={{
                          width: 400,
                          height: 200,
                          fontSize: "larger",
                          fontFamily: "sans-serif",
                        }}
                        id="description"
                        {...formik.getFieldProps("description")}
                      />
                    </div>

                    <ErrorPanel message={formik.errors.description} />
                  </div>
                </FormRow>

                <FormRow label="">
                  <div>
                    <input
                      type="submit"
                      value={"Create"}
                      className="bigButtonGreen"
                      disabled={
                        !formik.errors || !formik.values.title || isLoading
                      }
                    />
                  </div>
                </FormRow>
              </form>
            </div>
          </div>
        )}

        <Divider />

        <div className="flex-1">
          {data && Array.isArray(data) && (
            <div className="divCenterAlign">
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar></ListItemAvatar>
                  <ListItemText primary={<h3>My Tickets</h3>} />
                </ListItem>
                {data.map((ticket: ISupportTicket, index: number) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>{index + 1}</ListItemAvatar>
                      <ListItemText
                        primary={ticket.title}
                        secondary={
                          <div className="divSpread">
                            <Typography component={"h3"}>
                              {moment(ticket.created_date)
                                .local()
                                .format("MMM DD YYYY hh:mm A")}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              <Link to={`/support/${ticket.id}`}>
                                {capitalize(ticket.status)}
                              </Link>
                            </Typography>
                          </div>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                ))}
              </List>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
