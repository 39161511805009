import { Divider } from "@mui/material";
import { useLayoutContext } from "../providers/layout.provider";
import { useGetRewardsTotal } from "../api/api.get";

export default function RewardsComponent() {
  const { isMobile } = useLayoutContext();

  const { data } = useGetRewardsTotal();

  if (!data?.total) {
    return null;
  }

  return (
    <>
      <div className="divCenterAlign mb-60 mt-40">
        <div
          className="divSpread divColumn mr-20"
          style={{ width: 100, height: 80 }}
        >
          <span style={{ fontSize: isMobile ? 20 : 20, fontWeight: "bold" }}>
            Rewards
          </span>

          <div className="my-20" style={{ fontSize: 12 }}>
            <a
              href="https://help.expensehut.com/credits"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Learn More{" "}
            </a>
          </div>

          <div className="divColumn my-0">
            <span style={{ fontSize: 10 }}>Last Updated:</span>
            <span
              style={{ fontSize: 10, color: "darkgrey", fontWeight: "bold" }}
            >
              2 days ago
            </span>
          </div>
        </div>
        <span
          style={{
            fontSize: isMobile ? 150 : 200,
            fontWeight: "bold",
            color: "darkgrey",
          }}
        >
          {data?.total ?? 0}
        </span>
      </div>
      <Divider />
    </>
  );
}
