import { Link } from "react-router-dom";
import { useAuthContext } from "../../providers/auth.provider";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

export default function SettingsPage() {
  const { user } = useAuthContext();
  return (
    <div className="body">
      <div className="title">Settings </div>

      <div className="divCenterAlign">
        <List sx={{ bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={<Link to="/settings/profile">Profile</Link>}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                  >
                    Update personal details & account deletion
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <div className="my-40">
            <Divider />
          </div>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Link to="/settings/change-password">Change Password</Link>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                  >
                    Change Password
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <div className="my-40">
            <Divider />
          </div>
          {user?.admin === true && (
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={<Link to="/admin">Admin</Link>}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ color: "text.primary", display: "inline" }}
                    >
                      Admin Magic
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );
}
