import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateAttachmentAsIdentity } from "../../api/api";
import { useDeleteAttachmentForUser } from "../../api/api.delete";
import { useGetAttachmentsForUser } from "../../api/api.get";
import { AttachmentData } from "../../types/types";
export default function AdminViewUserAttachmentsSection() {
  const { username } = useParams();

  const { data, refetch } = useGetAttachmentsForUser(username);

  const [deletableId, setDeletableId] = useState<string>();
  const { mutateAsync } = useDeleteAttachmentForUser(deletableId);
  const { mutateAsync: updateAttachmentAsync } =
    useUpdateAttachmentAsIdentity();

  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteAttachment = (id: string) => {
    setIsLoading(true);
    setDeletableId(id);
    mutateAsync(null)
      .then(() => {
        window.alert("File deleted successfully.");
        refetch();
      })
      .catch((err: Error) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleUseAsIdentity = (id: string) => {
    setIsLoading(true);
    setDeletableId(id);
    updateAttachmentAsync({
      username: username!!,
      attachmentId: id,
      action: "add",
    })
      .then(() => {
        refetch();
      })
      .catch((err: Error) => {
        console.error(err);
        window.alert("Error. Please check browser console");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <h4>Attachments</h4>

      <List sx={{ width: "90%" }}>
        {data &&
          data.map((item: AttachmentData, index: number) => {
            return (
              <div key={index}>
                <ListItem className="">
                  <ListItemAvatar>{item.id}</ListItemAvatar>

                  <ListItemText
                    primary={<img src={item.url} width={50} height={50} />}
                    secondary={
                      <Typography variant="subtitle1">
                        {moment(item.createdDate).format("DD MMM YYYY hh:mm a")}
                      </Typography>
                    }
                  />
                  <a href={item.url}>{item.originalFileName}</a>

                  <div className="ml-20">
                    <IconButton
                      color="error"
                      aria-label="delete"
                      disabled={isLoading}
                      onClick={() => handleDeleteAttachment(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>

                  <div
                    className="ml-20"
                    style={{ color: "var(--exhut-green)" }}
                  >
                    <Tooltip title="Use as identity">
                      <IconButton
                        color="inherit"
                        aria-label="check"
                        disabled={isLoading}
                        onClick={() => handleUseAsIdentity(item.id)}
                      >
                        <AccountBoxIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </ListItem>
                <Divider variant="fullWidth" component="li" />
              </div>
            );
          })}
      </List>
    </div>
  );
}
