import { Button, Dialog } from "@mui/material";
import { IStatusMsg } from "../../types/types";

interface IProps {
  title: string;
  subtitle: string;
  onProceed: () => void;
  statusMsg?: IStatusMsg;
}

export default function OKDialogComponent({
  title,
  subtitle,
  onProceed,
  statusMsg,
  children,
}: React.PropsWithChildren<IProps>) {
  if (!statusMsg) {
    return null;
  }
  return (
    <Dialog open={true} onClose={onProceed} id="confirm-dialog">
      <div className="p-40">
        <h2 className="divCenterAlign">{title} </h2>
        <div className="divCenterAlign">
          {statusMsg.type === "success" && (
            <img
              src={"/assets/checked.png"}
              className="iconSizeLarge"
              alt="success"
            />
          )}
          {statusMsg.type === "error" && (
            <img
              src={"/assets/cancel.png"}
              className="iconSizeLarge"
              alt="success"
            />
          )}

          <p style={{ margin: "0px 20px" }}>{subtitle}</p>
        </div>

        {children}

        <div className="divCenterAlign" style={{ marginTop: 40 }}>
          <Button onClick={onProceed} variant="contained">
            {"OK"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
