import { useLayoutContext } from "../providers/layout.provider";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
export default function ContactUsPage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className="body">
      <div className="">
        <div className={`p-40 ${isMobile ? "divColumn" : "divFlex"}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 600,
            }}
          >
            <div className="divColumn my-40">
              <h1>
                ExpenseHut{" "}
                <span style={{ fontSize: 15, color: "grey" }}>d.b.a</span>
              </h1>
              <h3>309 Marjorie Dr</h3>
              <h3>Hutto, TX 78634</h3>

              <div className="mt-20 divAlignItemsOnly">
                <EmailIcon />
                <div className="mx-10">help@expensehut.com</div>
              </div>

              <div className="mt-20 divAlignItemsOnly">
                <LocalPhoneIcon />
                <div className="mx-10"> +1 818 561 1139</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
