import { Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface ITileProps {
  title: string;
  iconComp?: any;
  subtitle: string | JSX.Element;
  route?: string;
  id: string;
  external?: string;
}

export default function Tile({
  title,
  iconComp,
  subtitle,
  route,
  external,
  id,
}: ITileProps) {
  return (
    <Card
      id={id}
      sx={{
        width: 300,
        height: 250,
        margin: "2%",
        padding: 3,
        borderRadius: 10,
        boxShadow:
          "0.25rem 0.25rem 0.6rem rgba(0,0,0,0.5), 0 0.5rem 1.125rem rgba(75,0,0,0.05)",
      }}
    >
      <div className="divColumn" style={{ height: "100%" }}>
        <div className="divCenterAlign divColumn">
          {iconComp && iconComp()}
          <p style={{ fontSize: "xx-large", fontWeight: "bold" }}> {title}</p>
        </div>

        <Typography variant="body1" color="text.secondary">
          {subtitle}
        </Typography>

        <div className="my-20 divCenterAlign">
          {route && <Link to={route}>Show More</Link>}
          {!route && external && <a href={external}>Visit</a>}
        </div>
      </div>
    </Card>
  );
}
