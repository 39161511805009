import { Link } from "react-router-dom";

interface IProps {
  backToPath?: string;
  title: string;
  containerStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  backToLabel?: string;
}

export default function HeaderComponent({
  title,
  backToPath,
  containerStyle,
  titleStyle,
  backToLabel,
}: IProps) {
  return (
    <div
      className="divSpread"
      style={{ marginBottom: "20px", ...containerStyle }}
    >
      <div className="title" style={{ ...titleStyle, padding: "0px 0px" }}>
        {title}
      </div>

      {backToPath && (
        <div className="divAlignItemsOnly">
          <Link to={backToPath}>{backToLabel}</Link>
        </div>
      )}
    </div>
  );
}
