import moment from "moment";

export default function FooterComponent() {
  return (
    <div className="footer p-16">
      <div className="footer-copyright">
        {" "}
        &copy; {moment().format("YYYY")} www.expensehut.com{" "}
      </div>
    </div>
  );
}
