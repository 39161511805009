import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostLogout, usePostSession } from "../api/api";
import { IUser } from "../types/types";
import {
  EXHUT_HUB_LOCAL_STORAGE_TOKEN,
  EXHUT_REDIRECT,
  ROUTES,
} from "../utils/constants";
import { matchPublicRoutes, PUBLIC_ROUTES } from "../utils/functions";
export interface IAuthContext {
  user: IUser | null | undefined;
  setUser: (user: IUser | null) => void;
  signout: (timeout?: boolean) => void;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
  signout: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export default function AuthProvider(props: PropsWithChildren) {
  const { mutateAsync } = usePostSession();
  const { mutateAsync: logoutAsync } = usePostLogout();
  const [user, setUser] = useState<IUser | null>();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const bearerToken = useMemo(
    () => {
      if (!PUBLIC_ROUTES.includes(pathname)) {
        localStorage.setItem(EXHUT_REDIRECT, pathname);
      }
      return localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  const queryParams = new URLSearchParams(search);
  const tokenInQuery = queryParams.get("token");
  const shouldSession =
    !matchPublicRoutes(pathname) && !queryParams.get("logout");

  async function fetchData() {
    mutateAsync()
      .then((resp) => {
        setUser(resp);
        // navigate(ROUTES.HOME)
      }) // invoke session and set user across the state of the app.
      .catch(() => {
        setUser(null);
        navigate("/login");
      });
  }

  useEffect(() => {
    if (!!tokenInQuery) {
      localStorage.setItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN, tokenInQuery);
      mutateAsync()
        .then(() => {
          const redirect = localStorage.getItem(EXHUT_REDIRECT);
          navigate(redirect ?? "/home");
        })
        .catch(() => {
          setUser(null);
          navigate("/");
        });
    }
  }, [tokenInQuery]);

  useEffect(() => {
    if (shouldSession) {
      fetchData();
    }
  }, [bearerToken, shouldSession, pathname]);

  const signout = () => {
    localStorage.removeItem(EXHUT_REDIRECT);
    logoutAsync()
      .then(() => {
        setUser(null);
        localStorage.removeItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN);
        navigate("/");
      })
      .catch(console.error);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, signout }}>
      {props.children}
    </AuthContext.Provider>
  );
}
