import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { IProfile } from "../types/types";
import { EXHUT_HUB_LOCAL_STORAGE_TOKEN } from "../utils/constants";

export function useResetPasswordAdmin(username?: string) {
  return _usePut<any, any, void>({
    path: `admin/users/settings/passwords/reset?username=${username}`,
    keys: [],
  });
}

interface IUpdatePhone {
  phone: string;
}
export function useUpdatePhone() {
  return _usePut<any, any, IUpdatePhone>({
    path: `users/profile/phone`,
    keys: ["profile"],
  });
}

export type IUpdateProfile = Omit<IProfile, "email" | "entitlement">;

export function useUpdateProfile() {
  return _usePut<any, any, IUpdateProfile>({
    path: `users/profile`,
    keys: ["profile"],
  });
}

interface IUpdateSettings {
  entitlement?: number;
  status?: boolean;
}
interface IUpdateSettingsPayload {
  username: string;
  settings: IUpdateSettings;
}

export function useUpdateSettings() {
  return _usePut<any, any, IUpdateSettingsPayload>({
    path: `admin/users/settings`,
    keys: ["profile"],
  });
}

interface IUpdatePasswordPayload {
  password: string;
  newPassword: string;
}

export function useUpdatePassword() {
  return _usePut<any, any, IUpdatePasswordPayload>({
    path: `users/settings/passwords`,
    keys: ["settings"],
  });
}

function _usePut<T1, T2, T3>({
  path,
  keys,
  resetKeys,
}: {
  path: string;
  keys?: string[];
  resetKeys?: string[];
}) {
  const _url = `${process.env.REACT_APP_AUTH_BE_URL}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["Authorization"] = localStorage.getItem(
    EXHUT_HUB_LOCAL_STORAGE_TOKEN
  );

  const queryClient = useQueryClient();

  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      console.log("Req object in put", req);
      return axios
        .put(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}

interface UpdateTransferEnabled {
  enabled: boolean;
}

export function useUpdateTransferEnabled<T1, T2, UpdateTransferEnabled>(
  id?: string
) {
  const _url = `${process.env.REACT_APP_EXHUT_BE_URL}/exhut-be/admin/money-transfers/${id}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["bearer"] = localStorage.getItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN);

  const queryClient = useQueryClient();

  return useMutation<T1, T2, UpdateTransferEnabled>({
    mutationFn: async (req) => {
      return axios
        .put(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: ["admin-money-transfers", id],
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["admin-money-transfers", id],
      }),
  });
}
