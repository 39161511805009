import LoadingButton from "@mui/lab/LoadingButton";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDeleteUser } from "../../api/api.delete";
import {
  useGetUserByUsername,
  useGetUserVerificationDetailsByAdmin,
} from "../../api/api.get";
import { useResetPasswordAdmin, useUpdateSettings } from "../../api/api.update";
import ConfirmationDialog from "../../components/dialog/confirmation.dialog.component";
import FormRow from "../../components/forms/form.row.component";
import StatusMessageComponent from "../../components/status-message.component";
import { IStatusMsg } from "../../types/types";
import { ROUTES } from "../../utils/constants";
import IdVerificationSection from "./id-verification-section";

export default function AdminViewUserPage() {
  const { username } = useParams();
  const { data: user } = useGetUserByUsername(username);

  const [status, setStatus] = useState<boolean>(true);
  const [entitlement, setEntitlement] = useState<string>();

  const { mutateAsync } = useUpdateSettings();

  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [isResetPasswordLoading, setIsResetPasswordLoading] =
    useState<boolean>(false);
  const [deleteConf, showDeleteConf] = useState(false);

  const navigate = useNavigate();

  const { mutateAsync: resetPasswordAsync } = useResetPasswordAdmin(username);
  const { mutateAsync: deleteUserAsync } = useDeleteUser(username);

  const { data: verificationDetails } =
    useGetUserVerificationDetailsByAdmin(username);

  useEffect(() => {
    if (user) {
      setStatus(user.status);
      setEntitlement(user.entitlement?.toString());
    }
  }, [user]);

  const resetPasswordHandler = () => {
    setIsResetPasswordLoading(true);
    resetPasswordAsync()
      .then(() =>
        setStatusMsg({
          type: "success",
          text: "Password reset success",
        })
      )
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsResetPasswordLoading(false));
  };

  const updateStatusHandle = (value: boolean) => {
    setStatus(value);
    mutateAsync({
      username: username!!,
      settings: {
        status: value,
      },
    })
      .then(() =>
        setStatusMsg({
          text: "Status successfully changed",
          type: "success",
        })
      )
      .catch((err) => {
        setStatusMsg({
          text: "Whoops! Something unexpected happened.",
          type: "error",
        });
      });
  };

  const updateEntitlementHandle = (value: string) => {
    setEntitlement(value);
    mutateAsync({
      username: username!!,
      settings: {
        entitlement: +value,
      },
    })
      .then(() =>
        setStatusMsg({
          text: "Entitlement successfully changed",
          type: "success",
        })
      )
      .catch((err) => {
        setStatusMsg({
          text: "Whoops! Something unexpected happened.",
          type: "error",
        });
      });
  };

  const handleDeletion = () => {
    deleteUserAsync(undefined)
      .then(() => {
        navigate(ROUTES.ADMIN_USERS);
      })
      .catch(() => {
        setStatusMsg({
          text: "Whoops! Something unexpected happened on delete.",
          type: "error",
        });
      });
  };

  return (
    <div className="body">
      <StatusMessageComponent
        message={statusMsg}
        onClose={() => setStatusMsg(undefined)}
      />

      <ConfirmationDialog
        open={deleteConf}
        title={"Delete User"}
        subtitle="Are you sure?"
        onClose={() => showDeleteConf(false)}
        onProceed={handleDeletion}
      />

      <div style={{ margin: "0% 20%" }}>
        <div className="my-20">
          <Link to="/admin/users">Back</Link>
        </div>

        <div className="divSpread">
          <h1>{user?.email}</h1>

          {verificationDetails?.fraud && (
            <div
              className="divCenterAlign"
              style={{
                backgroundColor: "var(--exhut-dark-red)",
                height: 20,
                padding: 10,
                borderRadius: 20,
              }}
            >
              <span style={{ color: "white" }}>Fraud</span>
            </div>
          )}
        </div>

        <div>
          <h3>{user?.username}</h3>
        </div>

        <div className="divColumn">
          <div className="">
            <FormRow label="firstname" textSize="x-large">
              {user?.firstname ?? "--"}
            </FormRow>
          </div>
          <hr />
          <div className="">
            <FormRow label="lastname" textSize="x-large">
              {user?.lastname ?? "--"}
            </FormRow>
          </div>
          <hr />

          <div className="divSpread">
            <FormRow label="Country" textSize="x-large">
              {user?.countryCode}
            </FormRow>
          </div>
          <hr />

          <div className="divSpread">
            <FormRow label="entitlement" textSize="x-large">
              {user?.entitlement}
            </FormRow>
          </div>
          <hr />

          <div className="divSpread">
            <FormRow label="Status" color="red">
              <Switch
                checked={status}
                onChange={(e) => updateStatusHandle(e.target.checked)}
              />
            </FormRow>
          </div>
          <hr />

          <div className="divColumn">
            <FormRow label="Entitlement">
              <RadioGroup
                name="radio-buttons-group"
                value={entitlement}
                onChange={(e) => updateEntitlementHandle(e.target.value)}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio checked={entitlement === "0"} />}
                  label="Trial"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio checked={entitlement === "1"} />}
                  label="Basic"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio checked={entitlement === "2"} />}
                  label="Standard"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio checked={entitlement === "3"} />}
                  label="Premium"
                />
                <FormControlLabel
                  value="10"
                  control={<Radio checked={entitlement === "10"} />}
                  label="Unlimited"
                />
              </RadioGroup>
            </FormRow>
            <hr />

            <IdVerificationSection />

            <FormRow label="">
              <div className="divColumn my-20">
                <LoadingButton
                  variant="contained"
                  color="warning"
                  loading={isResetPasswordLoading}
                  onClick={resetPasswordHandler}
                >
                  {" "}
                  Reset Password
                </LoadingButton>

                <div className="my-80 w-100">
                  <LoadingButton
                    variant="contained"
                    color="error"
                    loading={isResetPasswordLoading}
                    onClick={() => showDeleteConf(true)}
                    sx={{ width: "inherit" }}
                  >
                    {" "}
                    Delete
                  </LoadingButton>
                </div>
              </div>
            </FormRow>
          </div>
        </div>
      </div>
    </div>
  );
}
