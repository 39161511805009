import { Link } from "react-router-dom";

export default function AdminLandingPage() {
  return (
    <div className="splitright">
      <div className="title">Admin </div>

      <div className="form-body divColumn">
        <table style={{ lineHeight: 5 }}>
          <tbody>
            <tr>
              <td>Users</td>
              <td>
                <Link to="/admin/users">Users</Link>
              </td>
            </tr>
            <tr>
              <td>Candidates</td>
              <td>
                <Link to="/admin/users">Candidates</Link>
              </td>
            </tr>
            <tr>
              <td>Support</td>
              <td>
                <Link to="/support">Support Cases</Link>
              </td>
            </tr>
            <tr>
              <td>Money Transfers</td>
              <td>
                <Link to="/admin/money-transfers">Money Transfers</Link>
              </td>
            </tr>
            <tr>
              <td>Job Reviews</td>
              <td>
                <Link to="/admin/jobs/reviews">Jobs Reviews</Link>
              </td>
            </tr>
            <tr>
              <td>Job Complaints</td>
              <td>
                <Link to="/admin/jobs/complaints">Job Complaints</Link>
              </td>
            </tr>
            <tr>
              <td>Referrals</td>
              <td>
                <Link to="/admin/referrals">Referrals</Link>
              </td>
            </tr>
            <tr>
              <td>Schedule</td>
              <td>
                <Link to="/admin/scheduleops">Schedule Operations</Link>
              </td>
            </tr>
            <tr>
              <td>Browser Extensions</td>
              <td>
                <Link to="/admin/extensions">Browser Extensions</Link>
              </td>
            </tr>
            <tr>
              <td>Skills</td>
              <td>
                <Link to="/admin/skills">Skills</Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
