import { useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuthenticate } from "../../../api/api";
import { useUpdatePassword } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import StatusMessageComponent from "../../../components/status-message.component";
import { useLayoutContext } from "../../../providers/layout.provider";
import { IStatusMsg } from "../../../types/types";

export default function ChangePasswordPage() {
  const { isMobile } = useLayoutContext();
  const { mutateAsync, isLoading, isError } = useAuthenticate();
  const navigate = useNavigate();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg | undefined>();
  const { mutateAsync: updatePasswordAsync } = useUpdatePassword();

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      newPassword: Yup.string().required("Please enter new password."),
      confirmPassword: Yup.string()
        .required("Please confirm new password.")
        .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      updatePasswordAsync({
        newPassword: values.newPassword,
        password: values.password,
      })
        .then((resp) => {
          setStatusMsg({
            type: "success",
            text: "Password successfully updated.",
          });
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setStatusMsg({
              type: "error",
              text: "Please verify your current password.",
            });
          } else {
            setStatusMsg({
              type: "error",
              text: "Whoops! Something went wrong. Please try again.",
            });
          }
        })
        .finally(() => formik.resetForm());
    },
  });

  return (
    <div className="body">
      <div className="divCenterAlign divColumn">
        <StatusMessageComponent
          message={statusMsg}
          onClose={() => setStatusMsg(undefined)}
        />

        <h2>Change Password</h2>

        <div
          className="mb-80"
          style={{
            backgroundColor: "#f4f7f8",
            flex: 1,
            border: "1px solid darkgrey",
            borderRadius: 10,
            padding: "10px 40px",
          }}
        >
          <form
            onSubmit={formik.handleSubmit}
            className="divColumn divCenterAlign px-20 pb-40"
          >
            <div className="divColumn mt-40">
              <input
                id="password"
                placeholder="Current Password..."
                type="password"
                className="bordered"
                style={{ width: 300 }}
                {...formik.getFieldProps("password")}
              />
              <div className="divCenterAlign">
                <ErrorPanel message={formik.errors.password} />
              </div>
            </div>

            <div className="divColumn my-20">
              <input
                id="newPassword"
                placeholder="New password..."
                type="password"
                autoFocus={true}
                className="bordered"
                style={{ width: 300 }}
                {...formik.getFieldProps("newPassword")}
              />
              <div className="divCenterAlign">
                <ErrorPanel message={formik.errors.newPassword} />
              </div>
            </div>

            <div className="divColumn my-20">
              <input
                id="confirmPassword"
                placeholder="Confirm password..."
                type="password"
                autoFocus={true}
                className="bordered"
                style={{ width: 300 }}
                {...formik.getFieldProps("confirmPassword")}
              />
              <div className="divCenterAlign">
                <ErrorPanel message={formik.errors.confirmPassword} />
              </div>
            </div>

            <div style={{ height: 70 }} className="mx-20">
              <input
                type="submit"
                disabled={isLoading || Object.keys(formik.errors).length > 0}
                value={"Update"}
                className="bigButtonGreen"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
