import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function LinkComponent({
  path,
  title,
}: {
  path: string;
  title: string;
}) {
  return (
    <Typography variant="h6" style={{ fontWeight: "bold" }}>
      <Link style={{ color: "var(--exhut-total-green)" }} to={path}>
        {title}
      </Link>
    </Typography>
  );
}
