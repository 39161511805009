import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllUsers } from "../../api/api.get";
import { AdminIUser } from "../../types/types";

export default function AdminUsersPage() {
  const { data: users } = useGetAllUsers();

  const [rowData, setRowData] = useState<AdminIUser[]>([]);
  const navigate = useNavigate();

  const colDefs = [
    { field: "firstName" },
    { field: "lastName" },
    { field: "email" },
    { field: "phone" },
    { field: "countryCode" },
    { field: "status" },
    { field: "created_at" },
  ];

  useEffect(() => {
    if (users) {
      setRowData(users);
    }
  }, [users]);

  return (
    <div className="body">
      <div className="my-40">
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500, width: "80vw" }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            onRowClicked={(e) => navigate("/admin/users/" + e?.data?.username)}
            rowData={rowData}
            columnDefs={colDefs as any}
          />
        </div>
      </div>
    </div>
  );
}
