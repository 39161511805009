import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function SuccessPill({
  title,
  successTooltip,
  errrorTooltip,
  success,
  errorColor = "orange",
}: {
  title?: string;
  errrorTooltip?: string;
  successTooltip?: string;
  success: boolean;
  errorColor?: string;
}) {
  return (
    <div
      style={{
        color: success ? "limegreen" : "red",
        padding: 10,
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        marginBottom: 20,
        width: "fit-content",
      }}
    >
      <Tooltip title={success ? successTooltip : errrorTooltip}>
        <strong style={{ fontSize: 15 }} className="divCenterAlign">
          {title}
        </strong>
      </Tooltip>
    </div>
  );
}
