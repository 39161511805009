import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Button, Divider, Paper, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDeleteMe } from "../../api/api.delete";
import {
  useGetProfile,
  useGetUserVerificationDetails,
} from "../../api/api.get";
import { useUpdateProfile } from "../../api/api.update";
import ConfirmationDialog from "../../components/dialog/confirmation.dialog.component";
import CountryDropdown from "../../components/dropdowns/country.dropdown.component";
import FormRow, { FormRowCol } from "../../components/forms/form.row.component";
import StatusMessageComponent from "../../components/status-message.component";
import { useAuthContext } from "../../providers/auth.provider";
import { useLayoutContext } from "../../providers/layout.provider";
import { IStatusMsg } from "../../types/types";
import {
  ROUTES,
  getCountryPhoneCode,
  getSubscription,
} from "../../utils/constants";
import { getISOCodes } from "../../utils/country";
import { capitalize, displayPhoneNumber } from "../../utils/functions";
import PhoneSection from "./profile/phone-section";
import OKDialogComponent from "../../components/dialog/ok.dialog.component";
import AddressSection from "./profile/address-section";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export default function ProfilePage() {
  const { user: userAuth } = useAuthContext();
  const { data, refetch } = useGetProfile();
  const { isMobile } = useLayoutContext();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [deleteConf, showDeleteConf] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (userAuth) {
      refetch();
    }
  }, [userAuth]);

  const user = data;

  const navigate = useNavigate();

  const { mutateAsync: deleteUserAsync, isLoading: isDeleteLoading } =
    useDeleteMe();
  const { mutateAsync: updateProfileAsync, isLoading: isUpdateLoading } =
    useUpdateProfile();
  const { data: userVerificationDetails } = useGetUserVerificationDetails();
  const [verifiedProfile, setVerifiedProfile] = useState(false);

  useEffect(() => {
    if (userVerificationDetails) {
      setVerifiedProfile(
        userVerificationDetails.addressVerified &&
          userVerificationDetails.smsVerified
      );
    }
  }, [userVerificationDetails]);

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      countryCode: user?.countryCode ?? "USA",
      dob: user?.dob,
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required("Please enter last name."),
      firstName: Yup.string().required("Please enter first name."),
    }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      setStatusMsg(undefined);

      // @ts-ignore
      delete values["consent"];

      updateProfileAsync({ ...values })
        .then(() => {
          setStatusMsg({
            type: "success",
            text: "Profile successfully saved.",
          });
        })
        .catch((err) => {
          if (err.response.status === 409) {
            setStatusMsg({
              type: "error",
              text: err.response.data?.message ?? "Whoops! Please try again",
            });
          } else {
            setStatusMsg({
              type: "error",
              text: "Whoops! Something went wrong. Please try again.",
            });
          }
        })
        .finally(() => setIsEditMode(false));
    },
  });
  const countryCode = useMemo(
    () => getCountryPhoneCode(formik.values.countryCode),
    [formik.values.countryCode]
  );

  const handleDeletion = () => {
    deleteUserAsync(undefined)
      .then(() => {
        navigate(ROUTES.ADMIN_USERS);
      })
      .catch(() => {
        setStatusMsg({
          text: "Whoops! Something unexpected happened on delete.",
          type: "error",
        });
      });
  };

  return (
    <div className="body" style={{ margin: "0% 10%" }}>
      <ConfirmationDialog
        open={deleteConf}
        title={"Delete Account?"}
        subtitle="This will permanently delete your profile including email, first name, last name and phone number. Are you sure?"
        onClose={() => showDeleteConf(false)}
        onProceed={handleDeletion}
      />

      <div>
        <div className="mb-40"></div>

        <OKDialogComponent
          title="Profile"
          onProceed={() => setStatusMsg(undefined)}
          subtitle={statusMsg?.text}
          statusMsg={statusMsg}
        />

        <div className="divCenterAlign" style={{}}>
          <div
            style={{
              width: "100%",
              padding: "60px 40px 40px 40px",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              borderRadius: 0,
              backgroundColor: "white",
              boxShadow: "3px 1px 5px 5px #e6ddd6",
            }}
          >
            <div className="divCenterAlign divColumn">
              <Avatar sx={{ width: 120, height: 120 }} />

              <div className="my-20">
                <strong style={{ fontSize: 20 }}>{`${capitalize(
                  formik.values.lastName
                )}, 
                ${capitalize(formik.values.firstName)} `}</strong>
              </div>

              <div
                style={{
                  backgroundColor: verifiedProfile ? "limegreen" : "orange",
                  color: "white",
                  padding: 10,
                  alignItems: "center",
                  textAlign: "center",
                  display: "flex",
                  marginBottom: 20,
                  marginTop: isMobile ? 20 : 0,
                }}
              >
                <Tooltip title="Please fill your phone number and address">
                  <strong style={{ fontSize: 15 }} className="divCenterAlign">
                    {verifiedProfile
                      ? "Profile Verified"
                      : "Profile unverified"}
                  </strong>
                </Tooltip>
              </div>
            </div>

            <div className="">
              <div
                id="subscription-banner"
                className="divCenterAlign "
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  borderRadius: 20,
                  padding: 20,
                  fontWeight: "bold",
                }}
              >
                {getSubscription(user?.entitlement)}
              </div>

              <div className="mt-20">
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode={getISOCodes(user?.countryCode)}
                  style={{
                    fontSize: 40,
                  }}
                  aria-label="United States"
                />
              </div>
            </div>
          </div>
        </div>

        <Paper
          elevation={6}
          sx={{
            minWidth: "40%",
            paddingLeft: 3,
            paddingRight: 3,
            marginTop: 5,
            boxShadow: "3px 1px 5px 5px #e6ddd6",
          }}
        >
          <div className="p-10">
            <h3 style={{ color: "darkgrey" }}> Personal Information</h3>
          </div>
          {!isEditMode && (
            <div className="divRight mx-20">
              <button
                className="buttonAsLink"
                style={{
                  fontWeight: "bold",
                  color: "var(--exhut-total-green)",
                  fontSize: 15,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditMode(!isEditMode);
                }}
                id="edit-profile-btn"
              >
                Edit
              </button>
            </div>
          )}
          <form style={{ padding: 30 }}>
            <div className="divColumn mt-0">
              <FormRowCol label="First Name">
                {!isEditMode && (
                  <strong>{capitalize(formik.values.firstName)}</strong>
                )}
                {isEditMode && (
                  <input
                    type="text"
                    className="bordered"
                    {...formik.getFieldProps("firstName")}
                  />
                )}

                {formik.errors.firstName && (
                  <span style={{ fontSize: 15 }} className="errorMsg">
                    {formik.errors.firstName}
                  </span>
                )}
              </FormRowCol>
              <Divider />
              <FormRowCol label="Last Name">
                {!isEditMode && (
                  <strong>{capitalize(formik.values.lastName)}</strong>
                )}

                {isEditMode && (
                  <input
                    type="text"
                    className="bordered"
                    {...formik.getFieldProps("lastName")}
                  />
                )}

                {formik.errors.lastName && (
                  <span style={{ fontSize: 15 }} className="errorMsg">
                    {formik.errors.lastName}
                  </span>
                )}
              </FormRowCol>
              <Divider />

              <FormRowCol label="Email">
                <strong>{user?.email} </strong>
              </FormRowCol>
              <Divider />

              <FormRowCol label="Country">
                {!isEditMode && <strong>{formik.values.countryCode}</strong>}
                {isEditMode && (
                  <CountryDropdown
                    width={220}
                    onChange={(e) =>
                      formik.setFieldValue("countryCode", e.target.value)
                    }
                    value={formik.values.countryCode}
                  />
                )}
              </FormRowCol>

              <Divider />

              <FormRowCol label="Date Of Birth">
                {!isEditMode && (
                  <strong>
                    {formik.values.dob
                      ? moment(formik.values.dob).format("DD MMM YYYY")
                      : "-"}
                  </strong>
                )}
                {isEditMode && (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateField
                      slotProps={{
                        textField: { id: "dob", style: { borderWidth: 0 } },
                      }}
                      sx={{ width: 290, border: "0px" }}
                      value={moment(formik.values.dob)}
                      onChange={(newValue: any) =>
                        formik.setFieldValue(
                          "dob",
                          moment(newValue).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </LocalizationProvider>
                )}
              </FormRowCol>

              <Divider />
            </div>

            <div className="divSpread">
              <FormRow label="">
                {isEditMode && (
                  <div className="divSpread">
                    <LoadingButton
                      variant="contained"
                      color="info"
                      loading={isUpdateLoading}
                      onClick={formik.submitForm}
                      disabled={Object.keys(formik.errors).length > 0}
                      id="update-profile"
                    >
                      {" "}
                      Update
                    </LoadingButton>

                    <Button onClick={() => setIsEditMode(false)}>Cancel</Button>
                  </div>
                )}
              </FormRow>
            </div>
          </form>
        </Paper>

        <PhoneSection areaCode={countryCode} />

        <AddressSection />

        <div className="">
          <hr className="my-80" />

          <div className="my-20 pb-80">
            <div
              style={{
                margin: "1px solid black",
                background: "#ffb2b2",
                borderRadius: 10,
                color: "",
              }}
              className="p-16"
            >
              <p>Danger Zone</p>
              <div className="my-20">
                <LoadingButton
                  variant="contained"
                  id="delete-account"
                  color="error"
                  loading={isDeleteLoading}
                  onClick={() => showDeleteConf(true)}
                >
                  {" "}
                  Delete Account
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
