export default function NoAccessPage() {
  return (
    <div id="no-access-page" className="splitright">
      <h2 className="title error">Whoops!!</h2>
      <div className="divCenterAlign">
        <img
          alt="restricted"
          className="iconSizeLarge"
          src="/assets/cancel.png"
          style={{ margin: "0px 20px" }}
        />
        <h2 className="errorMsg">You do not have access to this page.</h2>
      </div>
    </div>
  );
}
