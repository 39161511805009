import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteIdvForUser,
  useDeleteUser,
  useResetActivitiesForUser,
} from "../../api/api.delete";
import {
  useGetUserByUsername,
  useGetUserVerificationDetailsByAdmin,
} from "../../api/api.get";
import { useResetPasswordAdmin } from "../../api/api.update";
import FormRow from "../../components/forms/form.row.component";
import { AttachmentData } from "../../types/types";
import AdminViewUserAttachmentsSection from "./attachments-section";
import { Switch } from "@mui/material";
import {
  useUpdateAddressVerificationForUser,
  useUpdateAttachmentAsIdentity,
  useUpdateFraudStatusForUser,
  useUpdateOfacVerificationForUser,
} from "../../api/api";

export default function IdVerificationSection() {
  const { username } = useParams();
  const { data: user } = useGetUserByUsername(username);
  const { data: verificationDetails, refetch: refetchVerificationDetails } =
    useGetUserVerificationDetailsByAdmin(username);
  const { mutateAsync: updateAttachmentAsync } =
    useUpdateAttachmentAsIdentity();
  const { mutateAsync: updateFraudStatusAsync } = useUpdateFraudStatusForUser();
  const { mutateAsync: deleteIdvForUser } = useDeleteIdvForUser(username);
  const { mutateAsync: resetActivitiesAsync } =
    useResetActivitiesForUser(username);
  const { mutateAsync: updateAddressVerifiedAsync } =
    useUpdateAddressVerificationForUser();

  const { mutateAsync: updateOfacVerifiedAsync } =
    useUpdateOfacVerificationForUser();

  const [fraudStatus, setFraudStatus] = useState<boolean>();
  const [addressVerificationStatus, setAddressVerificationStatus] =
    useState<boolean>();

  const [ofacVerificationStatus, setOfacVerificationStatus] =
    useState<boolean>();

  useEffect(() => {
    if (user || verificationDetails) {
      refetchVerificationDetails();
      setFraudStatus(verificationDetails?.fraud);
      setAddressVerificationStatus(verificationDetails?.addressVerified);
    }
  }, [user, verificationDetails]);

  const handleRemoveIDVerificationDocument = (attachmentId: string) => {
    console.log(attachmentId);
    try {
      updateAttachmentAsync({
        action: "remove",
        username: username!!,
        attachmentId,
      });
      alert("Success");
    } catch (err: any) {
      alert("Error removing attachment file from idv " + err.message);
      console.error("Error removing attachment file from idv" + err.message);
    }
  };

  const handleResetActivities = async () => {
    try {
      await resetActivitiesAsync(null);
      alert("Success");
    } catch (err: any) {
      alert("Error deleting activites " + err.message);
      console.error("Error deleting activities" + err.message);
    }
  };

  const fraudHandler = async (value: boolean) => {
    try {
      setFraudStatus(value);
      await updateFraudStatusAsync({
        username: user?.username!!,
        fraud: value,
        fraudDescription: "By admin",
      });
    } catch (err) {
      alert("Error updating fraud status");
      console.error(err);
    } finally {
      refetchVerificationDetails();
    }
  };

  const handleUpdateAddressVerification = async (value: boolean) => {
    try {
      setAddressVerificationStatus(value);
      await updateAddressVerifiedAsync({
        username: user?.username!!,
        addressVerified: value,
      });
    } catch (err: any) {
      alert("Error updating fraud status " + err.response?.data?.message);
      console.error("Error updating fraud status", err.response);
    } finally {
      refetchVerificationDetails();
    }
  };

  const handleUpdateOfacVerification = async (value: boolean) => {
    try {
      setOfacVerificationStatus(value);
      await updateOfacVerifiedAsync({
        username: user?.username!!,
        ofacVerified: value,
      });
    } catch (err: any) {
      alert("Error updating ofac status " + err.response?.data?.message);
      console.error(err);
    } finally {
      refetchVerificationDetails();
    }
  };

  const handleDeleteIdv = async () => {
    await deleteIdvForUser(null);
    alert("Idv successfully invalidated.");
  };

  return (
    <div className="divColumn">
      <div className="divSpread">
        <FormRow label="SMS verified" textSize="x-large">
          <span
            className={
              verificationDetails?.smsVerified ? "successMsg" : "errorMsg"
            }
          >
            {verificationDetails?.smsVerified ? "Yes" : "No"}
          </span>
        </FormRow>

        <FormRow label="Address verified" textSize="x-large">
          <div className="divFlex divAlignItemsOnly">
            <span
              className={
                verificationDetails?.addressVerified ? "successMsg" : "errorMsg"
              }
            >
              {verificationDetails?.addressVerified ? "Yes" : "No"}
            </span>
            <Switch
              color="success"
              checked={addressVerificationStatus}
              onChange={(e) =>
                handleUpdateAddressVerification(e.target.checked)
              }
            />
          </div>
        </FormRow>

        <FormRow label="OFAC verified" textSize="x-large">
          <div className="divFlex divAlignItemsOnly">
            <span
              className={
                verificationDetails?.ofacVerified ? "successMsg" : "errorMsg"
              }
            >
              {verificationDetails?.ofacVerified ? "Yes" : "No"}
            </span>
            <Switch
              color="success"
              checked={ofacVerificationStatus}
              onChange={(e) => handleUpdateOfacVerification(e.target.checked)}
            />
          </div>
        </FormRow>
      </div>

      <FormRow label="Fraud" color="red" mandatory>
        <div className="divSpread">
          <Switch
            color="error"
            checked={fraudStatus}
            onChange={(e) => fraudHandler(e.target.checked)}
          />

          <span className="errorMsg">
            {verificationDetails?.fraudDescription}
          </span>
        </div>
      </FormRow>

      <FormRow label="Reset Activities" color="red" mandatory>
        <Button color="error" onClick={handleResetActivities}>
          Reset Activities
        </Button>
      </FormRow>

      <div className="mb-20">
        <h4>ID Verification Docs</h4>
      </div>

      <List sx={{ width: "90%" }}>
        {verificationDetails?.attachments &&
          verificationDetails.attachments.map(
            (item: AttachmentData, index: number) => {
              return (
                <div key={index}>
                  <ListItem className="">
                    <ListItemAvatar>{item.id}</ListItemAvatar>

                    <ListItemText
                      primary={<img src={item.url} width={50} height={50} />}
                      secondary={
                        <Typography variant="subtitle1">
                          {moment(item.createdDate).format(
                            "DD MMM YYYY hh:mm a"
                          )}
                        </Typography>
                      }
                    />
                    <a href={item.url}>{item.originalFileName}</a>

                    <div className="ml-20">
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() =>
                          handleRemoveIDVerificationDocument(item.id)
                        }
                      >
                        <GridDeleteIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </div>
              );
            }
          )}
      </List>

      <Button color="error" onClick={handleDeleteIdv}>
        Delete IDV
      </Button>

      <hr />

      <AdminViewUserAttachmentsSection />
    </div>
  );
}
