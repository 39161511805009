import { CircularProgress } from "@mui/material";

export default function LoadingComponent({
  size,
  color = "primary",
}: {
  size?: number;
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
}) {
  return <CircularProgress size={size} color={color} />;
}
